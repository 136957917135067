import React from 'react'
import ReactDOM from "react-dom";
import Trans from './Trans';

const GlobalCapabilities = () => {
  return (
            <section className='w-full pt-10 xl:pt-16 flex place-content-center overflow-hidden px-4 xl:px-0 bg-indigo-100 relative' style={{ minHeight: '630px' }}>
                <div className='lg:w-full xl:w-10/12 2xl:w-8/12 mx-auto'>
                    <h2 className='homepage-section-heading text-purple-100 text-center'><Trans>Our global capabilities</Trans></h2>
                    <p className='text-purple-100 text-center mt-2'><Trans>Delivering simple yet effective employee engagement solutions globally.</Trans></p>
                    <div className='mx-auto max-w-7xl px-2'>
                        <div className='grid grid-cols-2 lg:grid-cols-5 place-content-center gap-5 z-10 relative py-8 lg:pb-16'>
                            <div className='rounded-lg shadow-xl border-orange py-12 px-4 md:px-6 lg:px-2 xl:px-6 bgFrostIndigo grid items-center lg:place-content-center min-h-200px lg:min-h-auto'>
                                <h2 className='text-newOrange text-4xl lg:text-7xl lg:text-center'>100+</h2>
                                <p className='text-purple-100 m-0 lg:text-center pl-1 md:pl-0'><Trans>Countries</Trans> <span className='opacity-0 lg:hidden'><Trans>Options</Trans></span></p>
                            </div>
                            <div className='rounded-lg shadow-xl border-orange py-12 px-4 md:px-6 lg:px-2 xl:px-6 bgFrostIndigo grid items-center lg:place-content-center min-h-200px lg:min-h-auto'>
                                <h2 className='text-newOrange text-4xl lg:text-7xl lg:text-center'>10k+</h2>
                                <p className='text-purple-100 m-0 mt-1 lg:text-center pl-1 md:pl-0'><Trans>Reward Options</Trans></p>
                            </div>
                            <div className='rounded-lg shadow-xl border-orange py-12 px-4 md:px-6 lg:px-2 xl:px-6 bgFrostIndigo grid items-center lg:place-content-center min-h-200px lg:min-h-auto'>
                                <h2 className='text-newOrange text-4xl lg:text-7xl lg:text-center'>16+</h2>
                                <p className='text-purple-100 m-0 mt-1 lg:text-center pl-1 md:pl-0'><Trans>Languages</Trans></p>
                            </div>
                            <div className='rounded-lg shadow-xl border-orange py-12 px-4 md:px-6 lg:px-2 xl:px-6 bgFrostIndigo grid items-center lg:place-content-center min-h-200px lg:min-h-auto'>
                                <h2 className='text-newOrange text-4xl lg:text-7xl lg:text-center'>700+</h2>
                                <p className='text-purple-100 m-0 mt-1 lg:text-center pl-1 md:pl-0'><Trans>Companies</Trans></p>
                            </div>
                            <div className='rounded-lg shadow-xl border-orange py-12 px-4 md:px-6 lg:px-2 xl:px-6 bgFrostIndigo grid items-center lg:place-content-center min-h-200px lg:min-h-auto'>
                                <h2 className='text-newOrange text-4xl lg:text-7xl lg:text-center'>3.2M+</h2>
                                <p className='text-purple-100 m-0 mt-1 lg:text-center pl-1 md:pl-0'><Trans>Users</Trans></p>
                            </div>
                        </div>
                        <div className='rounded-full p-16 absolute top-1/3 -right-10 lg:hidden' style={{ backgroundColor: 'rgba(200,103,79, 0.5)'}}></div>
                        <div className='rounded-full p-10 absolute top-10 -left-10 lg:hidden' style={{ backgroundColor: 'rgba(129, 136, 227, 0.3)'}}></div>
                    </div>
                </div>
                <div className='mx-auto min-h-20 w-full flex place-content-center absolute bottom-0'>
                    <picture>
                        <source className='xl:mb-10 z-0' style={{ transform: 'scale(2)' }} loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/2024/01/Global-Arc.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="650" />
                        <img className='xl:mb-10 z-0' style={{ transform: 'scale(2)' }} loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/Global-Arc.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/01/Global-Arc.webp" alt="vantage-rewards-social-recognition" width="400" />
                    </picture>
                </div>
            </section>
  )
}

const renderGlobalCapabilities=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#Global-Capabilities")
        if (id != null){
            ReactDOM.render(
                <GlobalCapabilities />,
                id
            )
        }
    }
}

setTimeout(renderGlobalCapabilities,100)

export default GlobalCapabilities