import React, {useEffect} from 'react'
import Feedback from '../components/Feedback'
import ClientsModule from '../components/Homepage2023Content/ClientsModule'
import Layout from '../components/layout'
import GlobalCapabilities from '../components/GlobalCapabilities'
import ClientsFeedbackCarousel from '../components/Carousels/ClientsFeedbackCarousel'
import AwardBadges from '../components/AwardBadges'
// import AOS from 'aos';
// import "aos/dist/aos.css";

const Home2023 = ({  location }) => {

    // useEffect(() => {
    //     AOS.init({
    //         once:true
    //     });
    //   }, []);

  return (
    <Layout location={location}>
        
        <section id='watchVideoClass' class="mx-auto flex place-content-center relative overflow-hidden bg-purple-80 w-11/12 2xl:max-w-7xl py-9 xl:py-0 z-0 lg:px-5 lg:pr-0 rounded-xl">
           <div class="xl:flex justify-center items-start z-10 lg:px-0 relative px-6 overflow-hidden">
               <div class="w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-2 xl:py-16 px-5 lg:px-6 xl:pb-5">
                       <div className="mt-2 mb-5 xl:my-3 flex justify-center items-center">
                                <h2 className="pl-1 text-center lg:text-left heroTagStyle">BEHAVIORAL SCIENCE POWERED EMPLOYEE ENGAGEMENT PLATFORM</h2>
                        </div>
                       <h1 class="text-indigo-100 lg:pr-3 md:w-5/6 xl:w-8/12 homepage-section-heading text-center xl:text-left">Recognize and Nurture Productive Behaviours for Business Growth</h1>
                     
                       <ul class="list-inside orangebullets text-gray-250 lato text-base md:text-lg xl:text-xl md:text-md md:mx-auto xl:mx-0 2xl:text-lg w-full mt-10 lg:mb-8 text-left">
                           <li class="py-1 text-indigo-100">Drive productive behavior with targeted recognition - <a href= "/products/rewards-recognition/" target='blank' class='text-orange newTab-icon'>Vantage Rewards</a></li>
                           <li class="py-1 text-indigo-100">Achieve holistic employee wellness - <a href= "https://www.vantagefit.io/" target='blank' class='text-orange newTab-icon'>Vantage Fit</a></li>
                           <li class="py-1 text-indigo-100">Get real-time anonymous employee feedback with - <a href= "/products/employee-survey/" target='blank' class='text-orange newTab-icon'>Vantage Pulse</a></li>
                       </ul>
                       <div class="hidden xl:flex justify-center items-center lg:justify-start w-5/6 lg:w-full xl:w-9/12 2xl:w-10/12 cursor-pointer pb-16">
                           <a class="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="/request-demo/">Request a demo</a>
                           <button class="youtube-video-btn vc-new-orange-border-btn-rounded-full text-newOrange lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle">Watch video</button>
                       </div>    
               </div>

              
               <div className='hidden bgHeroCircle xl:flex justify-center items-center xl:absolute transform xl:scale-110 2xl:scale-100 -bottom-48 -right-48 2xl:-right-56 rounded-full border border-dashed border-indigo-100'>
                <div className='flex justify-center rounded-full' style={{ height: '30rem', width: '30rem', backgroundColor: '#CDCDE4' }}>
                <picture>
                        <source srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/11/Vc-home-Hero-girl.webp" media="(min-width: 1200px)" width="400" height= "473"/>

                        <source srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2023/11/Vc-home-Hero-girl.webp" media="(min-width: 640px)" width="50" />

                        <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2023/11/Vc-home-Hero-girl.webp" alt="A description of the image." width="50"  decoding="async"/>        
                    </picture>
                </div>
               </div>             
              
               <div class='grid grid-cols-4 xl:block mb-3 md:mt-4'>
                   <div class='mx-auto xl:absolute right-1/3 transform xl:-translate-x-12 2xl:-translate-x-0 xl:bottom-5 2xl:bottom-8 xl:-translate-y-2 z-20'>
                       <a href='/products/rewards-recognition/' title = "Vantage Rewards"><img loading='lazy' class='scale-90 xl:scale-100 transform hover:scale-125 cursor-pointer transition duration-300 ease-in-out' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/HP-Rewards.webp' width="90" height="120" /></a>
                   </div>
                   <div class='mx-auto xl:absolute right-1/3 xl:bottom-56 z-20 mb-2 vPerksHeroPosition'>
                       <a href='/products/employee-discount/' title = "Vantage Perks"><img loading='lazy' class='scale-90 xl:scale-100 transform hover:scale-125 cursor-pointer transition duration-300 ease-in-out' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/HP-Perks.webp' width="90" height= "120" /></a>
                   </div>
                   <div class='mx-auto xl:absolute right-1/3 xl:bottom-96 z-20 vPulseHeroPosition'>
                       <a href='/products/employee-survey/' title = "Vantage Pulse"><img loading='lazy' class='scale-90 xl:scale-100 transform hover:scale-125 cursor-pointer transition duration-300 ease-in-out' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/HP-Pulse.webp' width="90" height="120" /></a>
                   </div>
                   <div class='mx-auto xl:absolute right-1/3 transform xl:translate-x-32 2xl:translate-x-44 xl:bottom-96 xl:-translate-y-16 2xl:-translate-y-10 z-20'>
                       <a href='https://www.vantagefit.io/' title = "Vantage Fit"><img loading='lazy' class='scale-90 xl:scale-100 transform hover:scale-125 cursor-pointer transition duration-300 ease-in-out' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/HP-vfit.webp' width="90" height="120" /></a>
                   </div>
               </div>

               <div class="flex flex-col md:flex-row xl:hidden justify-center items-center lg:justify-start w-5/6 lg:w-1/2 mx-auto mt-5 mb-8">
                   <a class="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mb-3 md:mb-0 md:mr-2" href="/request-demo/">Request a demo</a>
                   <button class="youtube-video-btn vc-new-indigo-border-btn-rounded-full lato rounded-full mt-3 md:mt-0 md:ml-2 modal-open modal-toggle">Watch video</button>
               </div>
           </div>
       </section>
        
       <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
    <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
            <svg class="fill-current text-white" width="30" height="30" viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
        </button>
        <div class="modal-content text-left">
            <div class="aspect-w-16 aspect-h-9">
                <iframe loading="lazy" class="video" src="" data-src="https://www.youtube.com/embed/_91XkfNchEA" title="Vantage Circle Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
        </div>   
    </div>
</div>
    
<section class='py-10 xl:py-16' id='clientsSection'></section>
    
{/* <section className='py-10 xl:py-5 w-full'>
    <div className='max-w-7xl mx-auto'>
        <h2 class="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-section-heading text-center px-1">Transform Your Workplace:</h2>
        <h2 class="lg:pr-3 w-full md:w-5/6 xl:max-w-5xl mx-auto homepage-section-heading text-center px-3"><span className='text-orange'>Mastering Productivity</span> Through Behaviours!</h2>
    </div>
    <div class="md:grid grid-cols-3 gap-8 justify-center items-center mt-9 xl:mt-12 px-6 pb-10 xl:pb-16 xl:px-0 2xl:px-6 max-w-7xl mx-auto">
        <div class="bg-purple-100 rounded-xl p-5 my-5 h-400">
            <div class="flex justify-end">
                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-problem-2x.webp" alt="Homepage-problem-2x" width="200" height="200" />
            </div>
            <h2 class="text-orange text-4xl md:text-5xl xl:text-7xl lato py-3">2X</h2>
            <p class="text-gray-500">more likely to look for jobs because of disengagement</p>
        </div>
        <div class="bg-purple-100 rounded-xl p-5 my-5 h-400">
            <div class="flex justify-end">
                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-problem-44.webp" alt="Homepage-problem-44" width="200" height="200" />
            </div>
            <h2 class="text-orange text-4xl md:text-5xl xl:text-7xl lato py-3">44%</h2>
            <p class="text-gray-500">cited lack of recognition as the main reason for leaving job</p>
        </div>
        <div class="bg-purple-100 rounded-xl p-5 my-5 h-400">
            <div class="flex justify-end">
                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/Homepage-problem-22.webp" alt="Homepage-problem-22" width="200" height="200" />
            </div>
            <h2 class="text-orange text-4xl md:text-5xl xl:text-7xl lato py-3">20%</h2>
            <p class="text-gray-500">decrease in employee productivity due to poor health</p>
        </div>
    </div>
</section> */}

    <section class='w-full py-10 xl:pb-16 xl:pt-0' id="workforce">
        <div class='w-10/12 xl:w-auto xl:max-w-7xl mx-auto'>
            <h2 class='homepage-section-heading text-left'>Aligning <span class= "text-orange">Behavior</span> with<span class='text-orange'> R&R program</span><br/> for Productivity</h2>
            <div class='flex mt-7'>
                <h2 class='homepage-div-heading text-left w-full xl:w-5/12'>R&R program<br/> effectiveness is higher by <span class='xl:hidden'>-</span></h2>
                <div class='pt-5 xl:w-6/12 border-b border-gray-500 hidden xl:block'></div>
            </div>
            
            <div class='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-10 mx-auto mt-6 md:mt-10 xl:mt-12'>
                <div class='pl-0 py-4 pr-4 flex flex-col justify-start items-center mt-9 lg:mt-0'>
                    <div class='w-full'>
                        <picture>
                            <source class='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/homepage-stats1.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="190" height="108" />
                            <img class='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/04/homepage-stats1.webp" alt="Vantage Rewards" width="150" height="87" />
                        </picture>
                    </div>
                    <div class='w-full'><p class='text-gray-500 pl-2 p-0 m-0 mt-6'>when driving behavioral reinforcement vs. driving employee retention</p></div>
                </div>
                <div class='pl-0 py-4 pr-4 flex flex-col justify-start items-center mt-4 lg:mt-0'>
                    <div class='w-full'>
                        <picture>
                            <source class='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/homepage-stats2.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="200" height="108" />
                            <img class='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/04/homepage-stats2.webp" alt="Vantage Rewards" width="150" height="87" />
                        </picture>
                    </div>
                    <div class='w-full'><p class='text-gray-500 pl-2 p-0 m-0 mt-6'>when using online R&R platforms vs. administering programs manually</p></div>
                </div>
                <div class='pl-0 py-4 pr-4 flex flex-col justify-start items-center mt-4 lg:mt-0'>
                    <div class='w-full'>
                        <picture>
                            <source class='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/homepage-stats3.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="200" height="108" />
                            <img class='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/04/homepage-stats3.webp" alt="Vantage Rewards" width="150" height="87" />
                        </picture>
                    </div>
                    <div class='w-full'><p class='text-gray-500 pl-2 p-0 m-0 mt-6'>when programs are well-designed vs. poorly designed</p></div>
                </div>
            </div>
        </div>
    </section>

   <section class="w-full flex flex-col place-items-center py-10 xl:py-16 bg-purple-80 px-2 xl:px-0">
        <h2 class="lg:pr-3 w-full md:w-4/6 xl:w-10/12 2xl:max-w-5xl mx-auto homepage-section-heading text-center px-2 pb-5 xl:pb-10">Unlock your employees' full potential with<span class="text-orange"> comprehensive solutions</span> for Business Growth</h2>
        {/* <p class="text-gray-500 text-base md:text-lg xl:text-2xl py-3 text-center px-3">Our SaaS products are powerful alone but stronger together</p> */}
        <div class="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 px-6 xl:px-0 2xl:px-6">
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Rewards-2.webp" alt="Hoempage-Rewards-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Rewards and Recognition platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/rewards-recognition/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Rewards</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Vfit-2.webp" alt="Hoempage-vfit-1" width="300" height= "231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Wellness <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="https://www.vantagefit.io/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Fit</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Pulse-2.webp" alt="Hoempage-pulse-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Employee Feedback <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/employee-survey/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Pulse</a></div>
            </div>
            <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                
                <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Perks-2.webp" alt="Hoempage-perks-1" width="300" height="231"/>
                <h3 class="lato w-5/6 mx-auto text-center">Corporate Discounts <br/> platform</h3>
                <div class="flex justify-center w-full mt-4"><a href="/products/employee-discount/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Perks</a></div>
            </div>
        </div>
        <h2 class="lato w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-div-heading text-center px-2 mt-20 mb-7"><span class="text-orange"></span>Services tailored to support your initiatives and enhance employee experience</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 justify-center items-center px-2 xl:px-4 2xl:px-6 w-11/12 2xl:max-w-7xl mt-2 xl:mt-5">
            <a href="/services/vantage-gifts/">
                <div class="rounded-lg shadow p-6 flex justify-start items-center bg-white h-40 transform hover:-translate-y-1 transition duration-500">
                    <div class="p-2 flex justify-center">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/vantage-swags-ico.webp" alt="Homepage-gifting" width="70" height= "70"/>
                    </div>
                    <div class="pl-5">
                        <h2 class="text-xl xl:text-xl lato font-bold text-indigo-100">Vantage Gifting</h2>
                        <p class="text-gray-500 m-0 text-sm">Corporate gifting solution</p>
                        <p class="text-orange pt-3 m-0">Learn more &gt;&gt;</p>
                    </div>
                </div>
            </a>
            <a href="/services/aire-consultation/">
                <div class="rounded-lg shadow p-6 flex justify-start items-center bg-white h-40 transform hover:-translate-y-1 transition duration-500">
                    <div class="p-2 flex justify-center">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1645004132/gatsbycms/uploads/2023/12/HP-aire.webp" alt="Homepage-AIRe" width="70" height="70"/>
                    </div>
                    <div class="pl-5">
                        <h2 class="text-xl xl:text-xl lato font-bold text-indigo-100">AIR<sup>e</sup> Consultation</h2>
                        <p class="text-gray-500 m-0 text-sm">AIR<sup>e</sup> program consultation</p>
                        <p class="text-orange pt-3 m-0">Learn more &gt;&gt;</p>
                    </div>
                </div>
            </a>
            <a href="/services/joining-kit/">
                <div class="rounded-lg shadow p-6 flex justify-start items-center bg-white h-40 transform hover:-translate-y-1 transition duration-500">
                    <div class="p-2 flex justify-center">
                        <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2023/12/HP-vantage-gift.webp" alt="Homepage-joining-kit" width="70" height="70"/>
                    </div>
                    <div class="pl-5">
                        <h2 class="text-xl xl:text-xl lato font-bold text-indigo-100">Vantage Onboarding</h2>
                        <p class="text-gray-500 m-0 text-sm">Customizable joining kits</p>
                        <p class="text-orange pt-3 m-0">Learn more &gt;&gt;</p>
                    </div>
                </div>
            </a>
        </div>
    </section>

    <section class="w-full flex justify-center items-center py-10 xl:py-16">
       <div class="mx-auto max-w-7xl">
           <h2 class="lg:pr-3 w-full md:w-5/6 xl:w-full mx-auto homepage-section-heading text-center px-1">Enhance the employee reward experience with our</h2>
           <h2 class="text-orange w-full md:w-4/6 xl:w-full mx-auto homepage-section-heading text-center px-2">Global Rewards Catalog</h2>
           <div className='my-10 mx-auto'>
            <a href='/amazon-business-integrations/'>
                <picture className='xl:pl-5'>
                    <source className='mx-auto' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/VC-Homepage-Amazon-banner-web-scaled.webp" media="(min-width: 1440px)" type="image/webp" alt="vantage-rewards-social-recognition" width="1200" />
                    <source className='mx-auto' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload//w_650/q_auto/gatsbycms/uploads/2023/12/VC-Homepage-Amazon-banner-web-scaled.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="650" />
                    <img className='mx-auto' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/VC-Homepage-Amazon-banner-web-scaled.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/VC-Homepage-Amazon-banner-Mobile.webp" alt="vantage-rewards-social-recognition" width="300" />
                </picture>
            </a>
           </div>
          
           <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center items-center gap-5 max-w-7xl px-8 md:px-10 xl:px-4">
               <a href="/global-rewards-catalog/">
                <div class="grid p-3 py-4 mt-2 xl:mt-0 rounded-lg shadow bg-white amazonFeatureBlock1 z-20" style={{ gridTemplateColumns: "1fr 2fr 1fr" }}>
                   <div class="flex items-center justify-start">
                   <svg
                        width="67px"
                        height="50px"
                        viewBox="0 0 67 48"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                        <title>gift-card (1) copy</title>
                        <g id="Homepage" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="Artboard" fillRule="nonzero">
                            <g id="gift-card-(1)-copy">
                                <path
                                d="M27.9131276,10 C26.9614139,10 25.921126,10.342263 25.2030674,11.1533698 C24.2626551,12.2155167 23.9678315,15.3896527 24.0027244,16.9915003 C25.5905619,17.0299695 28.6263831,16.9878231 29.8562509,15.7634549 C31.1422015,14.48308 31.4940968,12.2457829 30.1266353,10.8842266 C29.5314787,10.2916307 28.7368536,10 27.9131276,10 L27.9131276,10 Z"
                                id="XMLID_304_"
                                fill="#FF6D05"
                                />
                                <path
                                d="M15.0868724,10 C16.0385861,10 17.078874,10.342263 17.7969326,11.1533698 C18.7373449,12.2155167 19.0321685,15.3896527 18.9972756,16.9915003 C17.4094381,17.0299695 14.3734757,16.9878231 13.1437491,15.7634549 C11.8577985,14.48308 11.5059032,12.2457829 12.8733647,10.8842266 C13.4685213,10.2916307 14.2631464,10 15.0868724,10 L15.0868724,10 Z"
                                id="XMLID_861_"
                                fill="#FF6D05"
                                />
                                <path
                                d="M33.1829437,7.73810551 C35.6062756,10.1811125 35.9290935,14.0316228 34.2687858,17 L67,17 L67,6.16027023 C67,2.75808538 64.2746908,0 60.9129611,0 L24,0 L24,6.65128647 C26.9831949,4.94466005 30.7526648,5.2880552 33.1829437,7.73810551 C34.5635326,9.12994107 30.7526648,5.2880552 33.1829437,7.73810551 Z"
                                id="XMLID_366_"
                                fill="#FF6D05"
                                />
                                <path
                                d="M14.3538591,29.7757785 L11.348014,26.7480177 L16.0178619,22 L0,22 L0,41.8683659 C0,45.2547997 2.71261252,48 6.05868067,48 L19,48 L19,25.0519394 L14.3538591,29.7757785 Z"
                                id="XMLID_370_"
                                fill="#29294C"
                                />
                                <path
                                d="M26.9773822,22 L31.6065658,26.7088169 L28.578854,29.7287089 L24,25.0711106 L24,48 L60.9129611,48 C64.2746908,48 67,45.2547997 67,41.868509 L67,22 L26.9773822,22 Z"
                                id="XMLID_371_"
                                fill="#29294C"
                                />
                                <path
                                d="M8.7790544,17 C7.12619946,14.0311916 7.44807789,10.1809688 9.85983779,7.73810551 C12.2786533,5.2880552 16.0305621,4.94480379 19,6.65128647 L19,0 L6.05868067,0 C2.71261252,0 0,2.75808538 0,6.16027023 L0,17 L8.7790544,17 Z"
                                id="XMLID_425_"
                                fill="#FF6D05"
                                />
                            </g>
                            </g>
                        </g>
                    </svg>
                   
                    </div>
                   <h2 class="lato text-xl text-gray-500 w-full flex justify-start xl:justify-center items-center px-2">Gift Card</h2>
                   <div class="flex items-center justify-center relative"><img decoding="async" class="rightAr1 absolute right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/01/Group-3-1.webp" loading="lazy" alt="Group-3-1" width="65"/></div>
               </div>
               </a>

               <a href="/global-rewards-catalog/">
                <div class="grid p-3 py-4 mt-2 xl:mt-0 rounded-lg shadow bg-white amazonFeatureBlock1 z-20" style={{ gridTemplateColumns: "1fr 2fr 1fr" }}>
                   <div class="flex items-center justify-start">
                            <svg
                                width="67px"
                                height="48px"
                                viewBox="0 0 67 48"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                <title>Artboard</title>
                                <g id="Artboard" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <g id="Group-13-Copy" transform="translate(6, 0)">
                                    <g id="t-shirt-(1)" fillRule="nonzero">
                                        <path
                                        d="M27.9998203,5 C30.2997592,5 32.286356,3.3812782 33,1 C31.6384707,1.4137042 29.9030508,1.66339432 27.9998203,1.66339432 C26.0967696,1.66339432 24.3613497,1.4137042 23,1 C23.7132847,3.38107988 25.6997017,5 27.9998203,5 Z"
                                        id="Path"
                                        fill="#FF6D05"
                                        />
                                        <path
                                        d="M54.9314947,7.3973402 C54.8183408,7.07881768 54.5829135,6.81686744 54.2762067,6.67422312 L43.7902957,1.81796824 C41.5597854,1.05993691 39.2874006,0.461166842 36.992869,0.0235251102 C36.9120979,0.00765499617 36.831699,0 36.7524168,0 C36.1844137,0 35.6757792,0.39339212 35.5492254,0.970317441 C34.7113649,4.67085461 31.4460914,7.4414031 27.4998468,7.4414031 C23.5537884,7.4414031 20.2883287,4.67104132 19.4506544,0.970317441 C19.3226117,0.39339212 18.8149077,0 18.2472768,0 C18.1679946,0 18.0875957,0.00765499617 18.0068246,0.0235251102 C15.7124791,0.461166842 13.4400944,1.05993691 11.206048,1.81796824 L0.723859141,6.67403642 C0.415291255,6.81668073 0.17818903,7.07863097 0.070432211,7.39715349 C-0.0410467782,7.71941016 -0.0209470606,8.06724572 0.13612851,8.37120508 L4.68834233,17.3839362 C4.90143656,17.8116824 5.33488324,18.0684049 5.79345458,18.0684049 C5.91181958,18.0684049 6.03185956,18.0512278 6.15022457,18.0155667 L11.2062341,16.5122002 L11.2062341,46.7531692 C11.2062341,47.4398783 11.7647457,48 12.4511138,48 L42.5491382,48 C43.2336452,48 43.7904818,47.4396916 43.7904818,46.7531692 L43.7904818,16.5125736 L48.8500274,18.0159401 C48.9683924,18.0516012 49.0882463,18.0687783 49.2064252,18.0687783 C49.664066,18.0687783 50.0958377,17.8120558 50.3119096,17.3843096 L54.8657984,8.37157849 C55.0191518,8.06743243 55.0411126,7.71959687 54.9314947,7.3973402 Z"
                                        id="Path"
                                        fill="#29294C"
                                        />
                                    </g>
                                    <circle id="Oval" fill="#FF6D05" cx={28} cy={16} r={2} />
                                    <circle id="Oval" fill="#FF6D05" cx={28} cy={26} r={2} />
                                    <circle id="Oval" fill="#FF6D05" cx={28} cy={36} r={2} />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    <h2 class="lato text-xl flex justify-start xl:justify-center items-center px-2 text-gray-500 w-full">Merchandise</h2>
                    <div class="flex items-center justify-center relative">
                        <img decoding="async" class="rightAr1 absolute right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/01/Group-3-1.webp" loading="lazy" alt="Group-3-1" width="65" height="65"/></div>
                    </div>
              </a>

               <a href="/global-rewards-catalog/">
                <div class="grid p-3 py-4 mt-2 xl:mt-0 rounded-lg shadow bg-white amazonFeatureBlock1 z-20" style={{ gridTemplateColumns: "1fr 2fr 1fr" }}>
                   <div class="flex items-center justify-start">
                    <svg
                            width="67px"
                            height="50px"
                            viewBox="0 0 67 48"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                            <title>Artboard</title>
                            <g id="Artboard" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <g id="air-balloon-copy" transform="translate(15, 0)" fillRule="nonzero">
                                <path
                                    d="M13.8033541,29.5373237 L16.2599623,36 L19.7400377,36 L22.1966459,29.5373237 C23.3427999,26.5221661 24,22.3173743 24,18.0003667 C24,12.985861 23.2656722,8.2933339 21.9326179,4.78640698 C20.7936385,1.78921851 19.3231891,0 18.0001794,0 C16.6768109,0 15.2067202,1.78921851 14.0673821,4.78640698 C12.7343278,8.2933339 12,12.985861 12,18 C12,22.3173743 12.6572001,26.5221661 13.8033541,29.5373237 L13.8033541,29.5373237 Z"
                                    id="Path"
                                    fill="#FF6D05"
                                />
                                <path
                                    d="M23.3571984,1 C23.8892038,1.85326773 24.3789521,2.85184219 24.817414,3.98953257 C26.2808801,7.78753893 27.0870145,12.8073606 27.0870145,18.1247152 C27.0870145,22.8131354 26.3751459,27.2415329 25.0832361,30.5952428 L23,36 L24.2146191,36 C24.5822915,36 24.9351559,35.8525082 25.1951992,35.5903004 L31.3928988,29.3413799 C34.3638939,26.3449281 36,22.3619196 36,18.1247152 C36,10.049445 30.6618881,3.20910032 23.3571984,1 Z"
                                    id="Path"
                                    fill="#29294C"
                                />
                                <path
                                    d="M13,36 L10.9167639,30.5952428 C9.62485415,27.2415329 8.91298558,22.8131354 8.91298558,18.1250793 C8.91298558,12.8073606 9.71911993,7.78753893 11.182586,3.98953257 C11.621048,2.85184219 12.1111574,1.85326773 12.6431628,1 C5.33847307,3.20910032 0,10.0498091 0,18.1250793 C0,22.3615554 1.63610598,26.3449281 4.6071012,29.3413799 L10.8048008,35.5903004 C11.0648442,35.8525082 11.4177085,36 11.785381,36 L13,36 Z"
                                    id="Path"
                                    fill="#29294C"
                                />
                                <path
                                    d="M22.1849095,41.4825496 L14.8150905,41.4825496 L14.8150905,38.0071307 L12.353719,38.0071307 C12.2345909,38.0071307 12.1165623,38.0047538 12,38 L12,46.4787862 C12,47.3190191 12.630463,48 13.4075453,48 L23.5920882,48 C24.3691705,48 24.9996335,47.3190191 24.9996335,46.4787862 L25,38.0075269 L22.1849095,38.0075269 L22.1849095,41.4825496 Z"
                                    id="Path"
                                    fill="#FF6D05"
                                />
                                </g>
                            </g>
                        </svg>                   
                    </div>
                   <h2 class="lato text-xl text-gray-500 w-full flex justify-start xl:justify-center items-center px-2">Experience</h2>
                   <div class="flex items-center justify-center relative"><img decoding="async" class="rightAr1 absolute right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/01/Group-3-1.webp" loading="lazy" alt="Group-3-1" width="65"/></div>
               </div>
               </a>    
           </div>
       </div>
   </section>
    
   <section className="py-10 xl:py-16 px-4">
    <div className="w-full grid justify-center max-w-7xl mx-auto">
        <h2 className="homepage-heading w-11/12 md:w-10/12 lg::max-w-4xl text-center pb-10 xl:pb-12 lg:tracking-wide mx-auto">
            Seamless <span class="text-orange">integrations </span>with your existing HCM/HRIS/SSO platforms
        </h2>
    <div>
        <div className="hidden lg:grid grid-cols-3 mx-auto mt-5">
            <p className="mx-auto text-gray-500 text-lg">Social Integrations</p>
            <p className="mx-auto text-gray-500 text-lg">SSO Integrations</p>
            <p className="mx-auto text-gray-500 text-lg">HRIS Integrations</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto my-5">
            <div className="lg:border-r px-5">
                <p className="mx-auto text-gray-500 text-lg lg:hidden pb-6 text-center">Social Integrations</p>
                <div className="grid grid-cols-3 gap-5">
                    <div className="intLogo mx-auto" id="logo1"></div>
                    <div className="intLogo mx-auto" id="logo2"></div>
                    <div className="intLogo mx-auto" id="logo3"></div>
                    <div className="intLogo mx-auto" id="logo4"></div>
                    <div className="intLogo mx-auto" id="logo5"></div>
                    <div className="intLogo mx-auto" id="logo6"></div>
                </div>
            </div>
            <div className="lg:border-r px-5 pt-5 lg:pt-0 lg:pl-9">
                <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">SSO Integrations</p>
                <div className="flex xl:grid justify-center gap-5 pl-5 lg:pl-0">
                    <div className="intLogo mx-auto" id="logo7"></div>
                    <div className="intLogo mx-auto" id="logo8"></div>
                </div>
            </div>
            <div className="px-5 pt-5 lg:pt-0">
                <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">HRIS Integrations</p>
                <div className="grid grid-cols-3 gap-5 lg:pl-9">
                    <div className="intLogo mx-auto" id="logo9"></div>
                    <div className="intLogo mx-auto" id="logo10"></div>
                    <div className="intLogo mx-auto" id="logo11"></div>
                    <div className="intLogo mx-auto" id="logo12"></div>
                    <div className="intLogo mx-auto" id="logo13"></div>
                </div>
            </div>
        </div>
    </div>
        <div class="max-w-7xl mx-auto grid grid-cols-1 gap-5 lg:gap-10 mt-20 mb-2 lg:mb-8">
            <a href="/integration/" target="_blank" class="vc-new-orange-btn-rounded-full text-purple-100" rel="noopener">Explore all integrations</a>
        </div>
    </div>
</section>

    <div id="Global-Capabilities"></div>

   <section className='py-10 xl:py-16 bg-purple-175 px-3'>
        <h2 className='homepage-section-heading pb-8 lg:pb-16 text-center'>Empowering Value-driven Success for Our Clients</h2>
        <div id="clientsFeedback"></div>
   </section>

   <section className='px-5'>
        <div id='BadgesSection'></div>
   </section>

   {/* <section class="bg-purple-175 feedback py-10 xl:pb-20 relative">
       <div class="mx-auto lg:mx-w-5xl px-3" id="feedbackCarousel"></div>
     
        <div class="mx-auto lg:max-w-6xl gap-5 grid grid-cols-1 md:grid-cols-2 mt-5 px-7">
            <div class="bg-white px-0 py-3 rounded-lg lg:flex">
                <div class="lg:border-r border-gray-darklight lg:w-4/12 xl:h-full flex items-center">
                    <div class="p-6 mx-auto">
                        <a href="https://www.g2.com/products/vantage-circle/reviews">
                            <img decoding="async" class="capteraImages xl:self-center xl:justify-self-center" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/g2-rating-2.webp" loading="lazy" alt="G2-Rating" width="150"/>
                        </a>                        
                    </div>
                </div>
                <div class="lg:w-8/12 flex items-center">
                    <p class="text-gray-500 p-5 lg:p-6 m-0 text-center lg:text-left text-base">Vantage circle is an excellent software as it helps employees gain appreciation and recognition from fellow employees. Every employee can express their feeling of gratitude and appreciation with the help of posts and cool badges.</p>
                </div>
            </div>
            <div class="bg-white px-0 py-3 rounded-lg lg:flex">
                <div class="lg:border-r border-gray-darklight flex items-center lg:w-4/12 xl:h-full">
                    <div class="p-6 mx-auto">
                        <a href="https://www.capterra.com/p/167264/Vantage-Circle/reviews/"><img decoding="async" class="capteraImages xl:self-center xl:justify-self-center" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/capterra-rating-3.webp" loading="lazy" alt="Capterra-rating" width="150"/></a>                        
                    </div>
                </div>
                <div class="lg:w-8/12">
                    <p class="text-gray-500 p-5 lg:p-6 m-0 text-center lg:text-left text-base">Vantage Circle stands out from its competitors due to its ease of use and the variety of rewards that employees can enjoy. The platform allows for quick redemption of rewards. Furthermore, Vantage Circle provides a great way to reward employees for their hard work and dedication.</p>
                </div>
            </div>
        </div>

     <div class='absolute right-0 top-0 lg:block hidden'>
        <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/01/vc-thread.webp' loading='lazy' alt='vc-thread' width="250" />
     </div>
   </section> */}
    </Layout>
  )
}

export default Home2023
